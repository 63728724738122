import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DashboardService } from '../dashboard.service';
import HC_exporting from 'highcharts/modules/exporting';
import * as Highcharts from 'highcharts';
import { map } from 'rxjs/operators';
import { setClassMetadata } from '@angular/core/src/r3_symbols';


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
var price:number;
const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
  { position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na' },
  { position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg' },
  { position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al' },
  { position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si' },
  { position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P' },
  { position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S' },
  { position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl' },
  { position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar' },
  { position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K' },
  { position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca' },
];

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @Input() data: any = [];
  dayPrice: number[] = [5];
  time = [];
  bigChart = [];
  cards = [];
  pieChart = [];
  bitcoin= [];
  ethereum= [];
  tether= [];

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  chartOptions: {};
  Highcharts = Highcharts;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private dashboardService: DashboardService) { }
  
  ngOnInit() {
    this.bigChart = this.dashboardService.bigChart();
    this.cards = this.dashboardService.cards();
    this.pieChart = this.dashboardService.pieChart();
    this.dashboardService.getBitcoin().subscribe(bitcoin=>{
      console.log(bitcoin);
      this.dayPrice = bitcoin['market_data']['current_price']['usd'];
      this.dashboardService.setData(bitcoin);

      console.log(this.dayPrice)
      this.bitcoin = bitcoin;
    });
    this.dashboardService.getEthereum().subscribe((ethereum)=>{
      console.log(ethereum);
      this.ethereum = ethereum;
    });
    this.dashboardService.getTether().subscribe((tether)=>{
      console.log(tether);
      this.tether = tether;
    });

    this.dataSource.paginator = this.paginator;

    this.chartOptions = {

      title: {
          text: 'Solar Employment Growth by Sector, 2010-2017'
      },
  
      subtitle: {
          text: 'Source: thesolarfoundation.com'
      },
  
      yAxis: {
          title: {
              text: 'Number of Employees'
          }
      },
  
      xAxis: {
          accessibility: {
              rangeDescription: 'Range: July 2020 - December 31, 2020'
          }
      },
  
      legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle'
      },
  
      plotOptions: {
          series: {
            dataLabels:{
              enabled: true
            },
              label: {
                  connectorAllowed: false
              },
            pointStart: "July 2020"
          }
      },
  
      // series: [{
      //     name: 'crypto',
          data:{
            googleSpreadsheetKey: "1_GZ4lfUKYjzKr0rs50x8OVOtwm4ly38zRCaKwJbmiLQ"
          },
        // }, {
        //   name: 'Manufacturing',
        //   data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]
        // }, {
        //   name: 'Sales & Distribution',
        //   data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]
        //  }],
  
      responsive: {
        rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom'
              }
            }
        }]
      }
    }

    HC_exporting(Highcharts);

    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }

}
