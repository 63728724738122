import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { PostsComponent } from './modules/posts/posts.component';
import { ChartsModule } from 'ng2-charts';

const routes: Routes = [
  {path: '',
  component: DefaultComponent,
  children: [{path: '',component: DashboardComponent},
    {path:'profile',component: PostsComponent}]
}];

@NgModule({
  imports: [
    ChartsModule,
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


