import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';


@Component({
  selector: 'app-widget-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() label: string;
  @Input() total: string;
  @Input() percentage: string;


  Highcharts = Highcharts;
  chartOptions = {};

  @Input() data = [];
  constructor() { }

  ngOnInit(): void {
    HC_exporting(this.Highcharts)
    this.chartOptions = {
      chart: {
      type: 'area',
      backgroundColor: null,
      borderwidth: 0,
      margin:[2,2,2,2],
      height: 60
      },
      
        title: {
            text: null
        },
        subtitle: {
            text: null
        },
        credits:{
          enabled:false
        },
        exporting:{
          enabled: false
        },
        tooltip: {
            split: true,
          outside: true
        },
        legend:{
          enabled: false
        },
        xAxis:{
          labels:{
            enabled: false
          },
          title:{
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickOptions:[]
        },
        yAxis:{
          labels:{
            enabled: false
          },
          title:{
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickOptions:[]
        },
        plotOptions: {
            area: {
                stacking: 'normal',
                lineColor: '#666666',
                lineWidth: 1,
                marker: {
                    lineWidth: 1,
                    lineColor: '#666666'
                }
            }
        },
        series: [{
          name: 'Follwers',
          data: [10,20,30,40,50]
       }]

       
      };
      setTimeout(() => {
        window.dispatchEvent(
          new Event('resize')
        );
      }, 300);
  }

}
