<!-- <script src="node_modules/chart.js/dist/chart.js"></script> -->
<app-header (toggleSidebarForMe)="sidebarToggler()"></app-header>


<mat-drawer-container>
    <mat-drawer mode="side" [opened]="sidebarOpen">
        <app-sidebar></app-sidebar>
    </mat-drawer>
    <mat-drawer-content>
    
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>

<app-footer></app-footer>