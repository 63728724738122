<script src="node_modules/chart.js/dist/chart.js"></script>
<div class="back">  
  <br>

  <div class="bounds" >

	<div class="content" fxLayout="column" fxFlexFill >

		<div fxFlex="15" class="sec1" fxFlex.xs="55">
    </div>
    <div class="image"> 
      <a href="https://www.linkedin.com/in/michael-palermo-8b2869173/">
        <img src="https://avatars1.githubusercontent.com/u/45370280?s=400&u=8cf107bcbe8f7c4cea630dca8f798ee0980c7f49&v=4" >
      </a>

    </div>
      <div fxFlex="35" class="sec2">
        <mat-card-title>Things I Love</mat-card-title>
        <div fxLayoutAlign="center center">
            <mat-list role="list">
              <mat-list-item>
                <a mat-list-item href="https://api.time.com/wp-content/uploads/2015/02/cats.jpg?quality=85&w=1024&h=512&crop=1">Cats</a>
              </mat-list-item>
              <mat-list-item>
                <a mat-list-item href="https://m.media-amazon.com/images/S/aplus-media/sota/b8180a1b-24ce-418f-bbe7-276501b41469.__CR0,0,970,600_PT0_SX970_V1___.jpg">Cartoons</a>
              </mat-list-item>
              <mat-list-item>
                <a mat-list-item href="https://image.shutterstock.com/image-photo/selective-focus-irritated-programmer-breaking-260nw-1500472652.jpg">Coding</a>
              </mat-list-item>
            </mat-list>
        
      </div>
		</div>
    <h2>Click My Picture to visit my LinkedIn!</h2>


	</div>

  </div>


</div>
  <router-outlet></router-outlet>

  