import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { chart } from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { DashboardService } from 'src/app/modules/dashboard.service';

@Component({
  selector: 'app-widget-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss']
})
export class AreaComponent implements OnInit {
  
  chartOptions: {};
  @Input() data: any = [];

  Highcharts = Highcharts;
  
  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    // Apply the grey theme
Highcharts.setOptions({
	colors: ["#DDDF0D", "#7798BF", "#55BF3B", "#DF5353", "#aaeeee", "#ff0066", "#eeaaee", 
		"#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],
	chart: {
		backgroundColor: {
      linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
      stops: [
          [0, '#2a2a2b'],
          [1, '#3e3e40']
      ]
  },
		borderWidth: 0,
		borderRadius: 15,
		plotBackgroundColor: null,
		plotShadow: false,
		plotBorderWidth: 0
	},
	title: {
		style: { 
			color: '#FFF',
			font: '20px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif'
		}
	},
	subtitle: {
		style: { 
			color: '#DDD',
			font: '12px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif'
		}
	},
	xAxis: {
		gridLineWidth: 0,
		lineColor: '#999',
		tickColor: '#999',
		labels: {
			style: {
				color: '#999',
        fontWeight: 'bold',
        fontSize: '20px'
			}
		},
		title: {
			style: {
				color: '#AAA',
				font: 'bold 20px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif'
			}				
		}
	},
	yAxis: {
		alternateGridColor: null,
		minorTickInterval: null,
		gridLineColor: 'rgba(255, 255, 255, .1)',
		lineWidth: 0,
		tickWidth: 0,
		labels: {
			style: {
				color: '#999',
				fontWeight: 'bold'
			}
		},
		title: {
			style: {
				color: '#AAA',
				font: 'bold 18px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif'
			}				
		}
	},
	legend: {
		itemStyle: {
      color: '#FFF',
      fontSize: '20px'
		},
		itemHoverStyle: {
      color: '#FFF',
      fontSize: '16px'
		},
		itemHiddenStyle: {
			color: '#333'
		}
	},
	credits: {
    enabled: false,
		style: {
			right: '50px'
		}
	},
	tooltip: {
		backgroundColor: '#737373',
    borderWidth: 0,
		style: {
      color: '#FFF',
      fontSize:'20px'
		}
	},
	
	
  plotOptions: {
    series: {
        dataLabels: {
            color: '#FFFFFF',
            style: {
                fontSize: '18px'
            }
        },
        marker: {
            lineColor: '#333'
        }
    },
    boxplot: {
        fillColor: '#FFFFFF'
    },
    candlestick: {
        lineColor: 'white'
    },
    errorbar: {
        color: 'white'
    }
  },

	
});
    this.chartOptions = {
      styledMode: true,
      title: {
        text: 'Top 3 Cryptocurrencies\' Six Month Price Chart'
      },

      subtitle: {
        text: 'Source: digitalcoinprice.com'
      },

      yAxis: {
        title: {
          text: 'Price of Stock'
        }
      },

      xAxis: {
        accessibility: {
          rangeDescription: 'Range: July 2020 - December 31, 2020'
        },
        type: 'datetime'
      },

      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },

      plotOptions: {
        series: {
          dataLabels: {
            enabled: true
          },
          label: {
            connectorAllowed: false
          },
          pointStart: Date.UTC(2020, 6, 1),
          pointInterval: 30 * 24 * 3600 * 1000
        }
      },

      series: this.dashboardService.bigChart(),

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    },
      HC_exporting(Highcharts);

    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);

  }
}
