<mat-nav-list>
    <div class="profile-card">
        <div class="header">
            <h2>Michael Palermo</h2>
            <h4>michaelpalermooo@gmail.com</h4>
        </div>
        <img src="https://avatars1.githubusercontent.com/u/45370280?s=400&u=8cf107bcbe8f7c4cea630dca8f798ee0980c7f49&v=4">
    </div>

    <mat-divider></mat-divider>
    <h2 matSubheader>Pages</h2>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/">Dashboard</a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/profile">Profile</a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/articles">Articles</a>

    <mat-divider></mat-divider>
    <h2 matSubheader>Tools</h2>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/contacts">
        <mat-icon>import_contacts</mat-icon>
        Contacts
    </a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/contacts">
        <mat-icon>contact_phone</mat-icon>
        Leads
    </a>
</mat-nav-list>