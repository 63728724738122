import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ObservableInput, OperatorFunction, throwError, forkJoin } from 'rxjs';
import { catchError, mergeMap, retry, switchMap } from 'rxjs/operators';


var number: number;
@Injectable({
  providedIn:'root'
})


export class DashboardService {
  id:{};
  ngOnInit(){
    let coin = this.http.get("https://api.coingecko.com/api/v3/coins/bitcoin/history?date=1-1-2018&localization=false");
    let coin2 = this.http.get("https://api.coingecko.com/api/v3/coins/ethereum/history?date=1-1-2018&localization=false");
    
    console.log("btc");

    this.getBitcoin().subscribe((data) => {
      console.log(data);
    })

  
  }
  
  

  constructor(private http: HttpClient) {   }
  
  getCurrency(number){
    var listOfCurrencies:string[]= ["bitcoin", "ethereum","tether","XRP"];
      return listOfCurrencies[number];
  }
  getBitcoin():Observable<any>{
    const url = "https://api.coingecko.com/api/v3/coins/bitcoin/history?date=1-1-2018&localization=false";
    return this.http.get<any>(url);
  }
  getEthereum():Observable<any>{
    const url = "https://api.coingecko.com/api/v3/coins/ethereum/history?date=1-1-2018&localization=false";
    return this.http.get<any>(url);
  } 
  getTether():Observable<any>{
    const url = "https://api.coingecko.com/api/v3/coins/tether/history?date=1-1-2018&localization=false";
    return this.http.get<any>(url);
  }
    
  

  setData(data){
    // this.data = data;
    let unix_timestamp = 1234567
    var date = new Date(unix_timestamp * 1000);
    // Hours part from the timestamp
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + date.getSeconds();

    // Will display time in 10:30:23 format
    var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    console.log(formattedTime);
  }

  bigChart() {
    return [{
      name: 'Bitcoin',
      data: [9261.07, 11784.90, 11993.50, 10813.70, 13736.90, 19186.50,28655.80]
    }, {
      name: 'Ethereum',
      data: [230.70, 385.40, 480.65, 352.03, 395.00, 593.66, 746.06]
    }, {
      name: 'Tether',
      data: [1, 1, 1, 1, 1, 1, 1]
    }
    ];
  }

  cards() {
    return [71, 78, 39, 66];
  }

  pieChart() {
    return [{
      name: 'Chrome',
      y: 61.41,
      sliced: true,
      selected: true
    }, {
      name: 'Internet Explorer',
      y: 11.84
    }, {
      name: 'Firefox',
      y: 10.85
    }, {
      name: 'Edge',
      y: 4.67
    }, {
      name: 'Safari',
      y: 4.18
    }, {
      name: 'Sogou Explorer',
      y: 1.64
    }, {
      name: 'Opera',
      y: 1.6
    }, {
      name: 'QQ',
      y: 1.2
    }, {
      name: 'Other',
      y: 2.61
    }];
  }
}